var exports = {};
exports = wordCharacter;
var fromCode = String.fromCharCode;
var re = /\w/; // Check if the given character code, or the character code at the first
// character, is a word character.

function wordCharacter(character) {
  return re.test(typeof character === "number" ? fromCode(character) : character.charAt(0));
}

export default exports;